
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  reactive,
  toRefs,
} from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import {
  RepositoryFactory,
  MemoRepository,
  CompanyRepository,
} from "@/lib/https";
import BreadScrum from "@/components/atomics/BreadScrum.vue";
import useList from "@/lib/compositional-logic/useList";
import ListItemActionDropdown from "@/components/atomics/list-item/ListItemActionDropdown.vue";
import AfterLoginPage from "@/views/layouts/AfterLoginAdminPage.vue";
import { formatDate } from "@/lib/utility/dateTime";
import FlatButton from "@/components/atomics/button/FlatButton.vue";
import usePopupControl from "@/lib/compositional-logic/usePopupControl";
import UpdateTextPopup from "@/components/popups/UpdateTextPopup.vue";
import { AnonymousFunction } from "@/models/Function";
import ConfirmDeletePopup from "@/components/popups/ConfirmPopup.vue";
import useNotificationControl from "@/lib/compositional-logic/useNotificationControl";
import useAccount from "@/lib/compositional-logic/useAccount";

export default defineComponent({
  name: "Memo",
  components: {
    BreadScrum,
    ListItemActionDropdown,
    AfterLoginPage,
    FlatButton,
    UpdateTextPopup,
    ConfirmDeletePopup,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const { openNotification } = useNotificationControl();
    const { popupState, openPopup } = usePopupControl();

    const companyID = route.params.companyID;

    const state = reactive({
      memoToEdit: {
        id: "",
        memo: "",
        firstName: "",
        lastName: "",
        action: AnonymousFunction,
      },
      addPopup: false,
    });

    const databreadScrumLinks = ref([
      { text: t("company.titleListCompanies"), to: "/company" },
      { text: "", to: "/company/" + companyID },
      { text: t("company.memoTitle"), to: "" },
    ]);

    const actionDropdown = (id: string, memo: string) => [
      {
        icon: "icons/edit.svg",
        text: t("common.edit"),
        action: () => {
          state.memoToEdit.id = id;
          state.memoToEdit.memo = memo;
          state.addPopup = false;
          openPopup("updateText");
        },
      },
      {
        icon: "icons/delete.svg",
        text: t("member.buttonDelete"),
        isDelete: true,
        action: () => {
          state.memoToEdit.id = id;
          openPopup("confirmAction");
        },
      },
    ];

    const { getCompanySimple } =
      RepositoryFactory.getRepository<CompanyRepository>(CompanyRepository);

    const {
      getMemoList,
      createMemo,
      updateMemo,
      deleteMemo,
    } = RepositoryFactory.getRepository<MemoRepository>(MemoRepository);

    const getCompanyInfo = async () => {
      const response = await getCompanySimple(companyID as string);
      if (response) {
        databreadScrumLinks.value[1].text = response.name;
      }
    };
    onMounted(getCompanyInfo);

    const {
      list: memoListInfo,
      loadMore,
      reloadList,
    } = useList({
      initialLoadList: async ({ pageSize }) =>
        await getMemoList(companyID as string, { pageSize }),
      loadMoreList: async ({ page, pageSize }) =>
        await getMemoList(companyID as string, { page, pageSize }),
    });

    const handleLoad = computed(() => loadMore);

    const addTextPopup = () => {
      state.addPopup = true;
      openPopup("updateText");
    };

    const editTextPopupInfo = computed(() => ({
      initialTextValue: state.memoToEdit.memo,
      title: t("company.memoEditTitle"),
      label: t("company.memoEditLabel"),
      placeholder: t("company.memo"),
      fieldName: "memo",
      payloadType: "object",
      isMultiline: true,
      maxLength: 2000,
      action: (formData: any) => updateMemo(formData, companyID, state.memoToEdit.id),
    }));

    const addTextPopupInfo = computed(() => ({
      title: t("company.memoAddTitle"),
      label: t("company.memoEditLabel"),
      placeholder: t("company.memo"),
      fieldName: "memo",
      payloadType: "object",
      isMultiline: true,
      maxLength: 2000,
      btnText: t("common.addTo"),
      action: (formData: any) => createMemo(formData, companyID),
    }));

    const deleteMemoPopupInfo = computed(() => ({
      id: state.memoToEdit.id,
      title: t("company.memoDeleteTitle"),
      message: t("company.memoDeleteMessage"),
      action: (memoId: any) => deleteMemo(companyID, memoId),
    }));

    const onActionDone = () => {
      reloadList();
      openNotification();
    };

    return {
      ...toRefs(state),
      databreadScrumLinks,
      memoListInfo,
      handleLoad,
      formatDate,
      actionDropdown,
      popupState,
      editTextPopupInfo,
      addTextPopupInfo,
      addTextPopup,
      onActionDone,
      deleteMemoPopupInfo,
    };
  },
});
