import { ref, onMounted, Ref, watch } from "vue";
import ResponseList from "@/models/ResponseList";
import debounce from "lodash.debounce";

interface ListParameter {
  page?: number;
  pageSize?: number;
  keyword?: string;
}

type ListFunction<T> = (params: ListParameter) => T;

interface UseListParameter<T> {
  initialLoadList?: ListFunction<T>;
  loadMoreList?: ListFunction<T>;
  filterList?: ListFunction<T>;
}

export default function useList<ListT extends any>({
  initialLoadList,
  loadMoreList,
  filterList,
}: UseListParameter<Promise<false | ResponseList<ListT>>>) {
  const list = ref([]) as Ref<ListT[]>;

  const page = ref(1);
  const pageSize = 20;
  const keyword = ref("");
  const totalLength = ref(0);

  if (initialLoadList)
    onMounted(async () => {
      const response = await initialLoadList({ page: 1, pageSize });
      if (response) {
        list.value = response.data;
        totalLength.value = response.pagination.totalRecords;
      }
    });

  const loadMore = async () => {
    const canLoadMore = list.value.length < totalLength.value;
    if (!loadMoreList || !canLoadMore) return;

    page.value++;

    const response = await loadMoreList({
      page: page.value,
      pageSize,
      keyword: keyword.value,
    });
    if (response) list.value.push(...response.data);
  };

  const reloadList = async () => {
    if (!initialLoadList) return;

    page.value = 1;
    const response = await initialLoadList({
      page: 1,
      pageSize: pageSize * page.value,
      keyword: keyword.value,
    });
    if (response) {
      if (keyword.value == "")
        totalLength.value = response.pagination.totalRecords;
      list.value = response.data;
    }
  };

  const debouncedFilterList = debounce(
    async (params?: Record<string, string | number | boolean>) => {
      if (!filterList) return;

      page.value = 1;
      const response = await filterList({
        page: 1,
        pageSize,
        keyword: keyword.value,
        ...params,
      });
      if (response) {
        if (keyword.value == "")
          totalLength.value = response.pagination.totalRecords;
        list.value = response.data;
      }
    },
    500
  );

  watch(
    () => keyword.value,
    (keyword) => debouncedFilterList({ keyword })
  );

  const setFilterKeyword = (text: string) => {
    keyword.value = text;
  };

  return {
    list,
    totalLength,
    loadMore,
    reloadList,
    setFilterKeyword,
    debouncedFilterList,
    keyword,
  };
}
