import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-183479e1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "section-header" }
const _hoisted_2 = { class: "memo-content-tittle" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "title-name" }
const _hoisted_6 = { class: "memo-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadScrum = _resolveComponent("BreadScrum")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_ListItemActionDropdown = _resolveComponent("ListItemActionDropdown")!
  const _component_AfterLoginPage = _resolveComponent("AfterLoginPage")!
  const _component_UpdateTextPopup = _resolveComponent("UpdateTextPopup")!
  const _component_ConfirmDeletePopup = _resolveComponent("ConfirmDeletePopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AfterLoginPage, { onScrolledToBottom: _ctx.handleLoad }, {
      "top-part": _withCtx(() => [
        _createVNode(_component_BreadScrum, { paths: _ctx.databreadScrumLinks }, null, 8, ["paths"])
      ]),
      "right-part": _withCtx(() => [
        _createVNode(_component_FlatButton, {
          text: _ctx.$t('company.memoAddButton'),
          icon: require('icons/add-white.svg'),
          class: "add-btn",
          onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addTextPopup()))
        }, null, 8, ["text", "icon"])
      ]),
      "left-top-part": _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("company.memoWriter")), 1),
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("company.memo")), 1)
        ])
      ]),
      "left-bottom-part": _withCtx(() => [
        (_ctx.memoListInfo.length == 0)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "content-blank",
              innerHTML: _ctx.$t('company.memoNoItem')
            }, null, 8, _hoisted_3))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.memoListInfo, (memo, index) => {
                return (_openBlock(), _createBlock(_component_ListItemActionDropdown, {
                  key: index,
                  "has-divider": "",
                  class: "memo-title",
                  actions: _ctx.actionDropdown(memo.id, memo.memo)
                }, {
                  "title-primary": _withCtx(() => [
                    _createElementVNode("p", _hoisted_5, _toDisplayString(`${memo.lastName} ${memo.firstName}`), 1)
                  ]),
                  "title-secondary": _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("common.createdDate") + " " + _ctx.formatDate(memo.createdAt, "YYYY/MM/DD HH:mm")), 1)
                  ]),
                  body: _withCtx(() => [
                    _createElementVNode("p", _hoisted_6, _toDisplayString(memo.memo), 1)
                  ]),
                  _: 2
                }, 1032, ["actions"]))
              }), 128))
            ]))
      ]),
      _: 1
    }, 8, ["onScrolledToBottom"]),
    (_ctx.popupState.updateText)
      ? (_openBlock(), _createBlock(_component_UpdateTextPopup, _mergeProps({ key: 0 }, _ctx.addPopup ? _ctx.addTextPopupInfo : _ctx.editTextPopupInfo, { onOnUpdateTextDone: _ctx.onActionDone }), null, 16, ["onOnUpdateTextDone"]))
      : _createCommentVNode("", true),
    (_ctx.popupState.confirmAction)
      ? (_openBlock(), _createBlock(_component_ConfirmDeletePopup, _mergeProps({ key: 1 }, _ctx.deleteMemoPopupInfo, { onOnDone: _ctx.onActionDone }), null, 16, ["onOnDone"]))
      : _createCommentVNode("", true)
  ], 64))
}