import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export enum ConvertSecondsToItem {
  Day = 86400,
  Hour = 3600,
  Minute = 60,
}

export const formatDate = (date: string, format: string): string => {
  dayjs.tz.setDefault("Japan/Tokyo");
  const timedifference = dayjs.tz.guess();
  return dayjs(date).tz(timedifference).format(format);
};

/** @param date yyyy-MM format to yyyy/MM */
export const formatDateYearAndMonth = (date: string): string => {
  return date.replace("-","/")
}

export const formatDateMinuteBySeconds = (seconds: number): string => {
  const min = Math.floor(seconds / ConvertSecondsToItem.Minute);
  return `${min}分`;
}